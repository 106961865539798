import Layout from "../ui/modules/layout/layout";
import Ranking from "../ui/modules/ranking/ranking";

export default function Clasificacion() {

  return (
    <Layout title={'Clasificacion'}>
      <Ranking />
    </Layout>
  )
}
