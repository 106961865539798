export const constants = {
  eases: ['Linear'], // Possible eases
  colors: {
    text: {
      primary: '#4B4591', // Blue dark
      secondary: '#494232', // Brown dark
    },
    points: {
      primary: '#FBFD30', // Yellow
      secondary: '#50A5DA', // Blue light
    },
    status: {
      error: '#c42c2c', // Red
    }
  }
}
