import { constants } from "./constants";
import { randomIntFromInterval, pad } from "./math";
import {
  animateText,
  hideGameObject,
  loadSpriteInARandomPositionAndMoveIt,
  removeAllTweensFromScene,
  setGameText,
  showGameObject,
  showTextOnSceneByCoordinates
} from "./scene";

/****************************************************************************************************************************************
 * This file contains all the functions utils for the game, that means that here we can find form starting the game to ending it,
 * all the connections between scenes and the game logic.
 *****************************************************************************************************************************************/


/***********************************
 ******** HELPERS FUNCTIONS ********
 ***********************************/

/**
 * Initializes the generation of random beers in the given scene at a set frequency.
 *
 * @param {Phaser.Scene} scene - The scene in which to generate the beers.
 */
function initGeneratingBeers(scene) {
  const generatorBeersTimerId = setTimeout(() => {
    // Just if game is not paused.
    if (!window.game.paused) {
      const beerConfig = {
        sprite: `beer`,
        height: 50,
        onHit: (pointer) => {
          if (!window.game.muted) {
            scene.sound.play('beer-sound', { duration: .5 });
          }

          handleHitPointsElement(scene, pointer, 1, true);
        },
        onLost: () => {
          window.game.restartStrikes();
          window.game.restartLevelFrequency();
        },
      }
      loadSpriteInARandomPositionAndMoveIt(scene, beerConfig);
    }

    // Call recuservileydly.
    initGeneratingBeers(scene);
  }, window.game.values.levelFrequency);

  window.game.timers.generators.push(generatorBeersTimerId);
}

/**
 * Show an explosion animation at the position of the pointer, using the 'boom'
 * game object and playing the 'kaboom-boom' animation.
 *
 * @param {Phaser.Scene} scene - The scene where the explosion will be shown.
 * @param {Phaser.Input.Pointer} pointer - The pointer that triggered the explosion.
 */
function showExplosion(scene, pointer) {
  showGameObject(scene, 'boom', pointer, () => {
    scene.boom.play('kaboom-boom');
  });
}

/**
 * Handles the hit points element of the game.
 * @param {Phaser.Scene} scene - The Phaser scene where the game is happening.
 * @param {Phaser.Input.Pointer} pointer - The Phaser pointer that activates the hit points element.
 * @param {number} points - The amount of points to be added with this hit.
 * @returns {void}
*/
export function handleHitPointsElement(scene, pointer, points, isBeer = false) {
  const pointsAddedWithThisHit = window.game.addPoints(points);
  showExplosion(scene, pointer);

  const textToShow = pointsAddedWithThisHit.toString();
  const _isNotBasic = pointsAddedWithThisHit > 1;
  const _itsStrikePoint = _isNotBasic && isBeer;

  const color = _isNotBasic ? constants.colors.points.secondary : constants.colors.points.primary;
  const size = _isNotBasic ? (_itsStrikePoint ? '36px' : '24px') : '16px';

  showTextOnSceneByCoordinates(scene, pointer, textToShow, size, color);

  if (_itsStrikePoint && !window.game.muted && pointsAddedWithThisHit > 15) {
    scene['combo-sound'].play(); // Just sound in great strikes.
  }

  const _newScore = window.game.values.score;

  const text = setGameText(scene, 'scoreText', `${pad(_newScore)}`);

  // Change color to green when is rising the score above 75%
  if (_newScore >= window.game.values.levelScore * 0.75) {
    text.setColor('#32c532');
  }

  animateText(scene, text, points > 0 ? 'add' : 'subtract');

  // Level up if is above level score
  if (_newScore >= window.game.values.levelScore) {
    levelUp(scene);
  }
}

/**
 * Generate other game elements in the scene based on their probability and number of occurrences.
 * @param {Phaser.Scene} scene - The scene where the elements will be generated.
 */
function initGenerateOtherElements(scene, reactivating = false) {
  window.game.gameElements.forEach((gameElement) => {
    const shouldShowElement = gameElement.probability(window.game.values.level);
    const numberOfElementsToShow = reactivating ? 1 : gameElement.numOcurrencies?.(window.game.values.level) ?? 1;

    if (shouldShowElement) {
      if (gameElement.sprite === 'gift' && reactivating) {
        return; // No prices while reactivating
      }
      for (let i = 0; i < numberOfElementsToShow; i++) {
        generateSecondaryElement(scene, gameElement);
      }
    }
  });
}

const generateSecondaryElement = (scene, gameElement) => {
  const timeToShowElement = randomIntFromInterval(1000, window.game.values.timeLeft);
  const handleHitElement = (pointer) => gameElement.effect(scene, pointer);
  const elementGeneratorTimerId = setTimeout(() => {
    if (!window.game.paused) {
      loadSpriteInARandomPositionAndMoveIt(scene, { ...gameElement, onHit: handleHitElement });
    } else {
      generateSecondaryElement(scene, gameElement);
    }
  }, timeToShowElement);

  window.game.timers.generators.push(elementGeneratorTimerId);
}

const resetGameTime = (scene) => {
  clearInterval(window.game.intervals.gameTimer);
  startLevelCounter(scene);
}

export function handleHitSecondsElement(scene, pointer, timeToAdd, textInfo) {
  if (!window.game.isChangingLevel) { // Avoid some changes related to time during changing level.
    const newTimeLeft = window.game.values.timeLeft + timeToAdd;
    window.game.updateTime(newTimeLeft);
    const timeText = updateTimeText(scene);
    animateText(scene, timeText, timeToAdd > 0 ? 'add' : 'subtract');

    showExplosion(scene, pointer);

    const { text } = textInfo;
    showTextOnSceneByCoordinates(scene, pointer, text, '16px', '#D35D9A');

    resetGameTime(scene);


    initGenerateOtherElements(scene, true); // generating an ocurrencie of each extra element.
  }
}


const updateTimeText = (scene) => {
  const levelTimeInSeconds = window.game.values.timeLeft / 1000;
  const padText = pad(levelTimeInSeconds);
  return setGameText(scene, 'timeText', `${padText}`);
}

const makeGameNight = (scene) => {
  // Change bck
  scene.bg.setVisible(false);
  scene.nightBg.setVisible(true);

  // set game night
  window.game.isNight = true; // This will make text color changes.
}

/************************
 ****** GAME LOGIC ******
 ************************/

/**
* Starts the game and initializes the level.
*
* @param {scene} scene - The scene object.
*/
export function startGame(scene) {
  // Start game
  window.game.start(scene); // Initialize listeners and values for game.
  initializeLevel(scene);

  // Start music
  scene.gameMusic.play();
}

export async function endGame(scene) {
  // Stop music
  scene.gameMusic.stop();

  // End game logic
  await window.game.endGame();

  // Load game over scene
  window.game.changeScene('game-over-scene');
}

export function lossLife(scene) {
  // Change opacity of the life icon
  scene[`life-icon-${window.game.values.lifes - 1}`].alpha = 0.5;

  // Reduce in one the lifes availables.
  window.game.lossLife();

  // End actual level
  endLevel(scene);

  // Decide what to do. End the game or restart the level.
  if (window.game.values.lifes < 1) {
    endGame(scene);
  } else {
    // wait 1,2 second to show modal for restart level.
    setTimeout(() => {
      window.game.events.emit('start-level', 'restart'); // Show popup with a button that will restart level.
    }, 2000);
  }
}

/**************************
 ****** LEVELS LOGIC ******
 **************************/

/**
 * Sets the level and next level score text in the game scene.
 *
 * @param {Phaser.Scene} scene - The game scene to update.
 */
const showStartLevelMessage = (scene) => {
  const newText = `NIVEL ${window.game.values.level}`;
  const nextLevelScoreText = `${window.game.values.levelScore} PUNTOS`

  setGameText(scene, 'levelText', newText);
  showGameObject(scene, 'levelText');
  setGameText(scene, 'nextLevelScoreText', nextLevelScoreText);
  showGameObject(scene, 'nextLevelScoreText');
}

/**
 * Hides the level message and the next level score text in the given scene.
 *
 * @param {Phaser.Scene} scene - The scene to hide the texts in.
 */
const hideStartLevelMessage = (scene) => {
  // Hide level message.
  hideGameObject(scene, 'levelText');
  hideGameObject(scene, 'nextLevelScoreText');
}

/**
 * Starts a level counter that updates the game time every second and finishes
 * the game after a certain amount of time has passed.
 *
 * @param {Phaser.Scene} scene - The scene where the game is happening.
 */
const startLevelCounter = (scene) => {
  // Interval for update time every second.
  scene.timeText.setColor(window.game.isNight ? '#FBE5BD' : '#494232'); // reset color when stat level
  const timeIntervalId = setInterval(() => {
    if (!window.game.paused) {
      const _timeLeft = window.game.values.timeLeft - 1000;
      window.game.updateTime(_timeLeft);
      const timeText = updateTimeText(scene);

      // Change color of time and animate it every second when its less than 5 s.
      if (_timeLeft <= 5000) {
        animateText(scene, timeText, 'subtract');
      }

      // If time arrive sbut is not changing, avoiding some bug situations.
      if (_timeLeft <= 0 && !window.game.isChangingLevel) {
        showTextOnSceneByCoordinates(scene, { x: scene.cameras.main.width / 2, y: scene.cameras.main.height / 2 }, 'Tiempo agotado!', '20px', '#D35D9A', 1500);
        lossLife(scene); // Loss a life if the time arrives to 0.
      }
    }
  }, 1000);
  window.game.intervals.gameTimer = timeIntervalId;
}


/**
 * Initializes a level by showing the start level message, setting up nex level score text and time logic,
 * generating beers at random intervals, and generating other game elements.
 *
 * @param {Phaser.Scene} scene - The Phaser scene object.
 * @returns {Promise<void>} - A promise that resolves after the start level message is hidden.
 */
export async function initializeLevel(scene, loadAwards = true) {
  const _startTime = Date.now(); // For showing at least a message if its immeditaly.

  // Show start level message and wait for a certain time.
  showStartLevelMessage(scene);

  // Score
  const scoreText = setGameText(scene, 'scoreText', `${pad(window.game.values.score)}`);
  scoreText.setColor(window.game.isNight ? '#50A5DA' : '#4B4591'); // Reset color

  // Load awards if needed
  const level = window.game.values.level;
  const needToLoadAwards = level === 1 || level === 5 || level === 10;
  if (loadAwards && needToLoadAwards) {
    await window.game.loadPossibleAwards();
  }

  // Add some time between levels
  const startLevelMessageDuration = _startTime - Date.now() > 3000 ? 0 : 1500;
  await new Promise(resolve => setTimeout(resolve, startLevelMessageDuration));

  // Generate beers at random intervals using game.levelFrequency based on the level and user skill.
  initGeneratingBeers(scene);

  // Generate other elements of the game.
  initGenerateOtherElements(scene);

  // Hide message for starting level
  hideStartLevelMessage(scene);

  // Time logic.
  updateTimeText(scene);
  startLevelCounter(scene);

  window.game.isChangingLevel = false;
}

/**
 * Executes necessary steps to level up the game.
 *
 * @param {object} scene - The current scene object.
 */
export function levelUp(scene) {
  // avoid some bug situations between changing level and ending game.
  window.game.isChangingLevel = true;

  // Level up in the game values.
  window.game.levelUp();

  // Change background when level is more than 10
  if (window.game.values.level > 10) {
    makeGameNight(scene);
  }
  // Make the logic behind levelling up
  endLevel(scene);

  // Show modal for next level.
  window.game.events.emit('start-level', 'new'); // Show popup with a button that will start next level.
}

/**
 * This function removes all tweens from the given scene, clears all timers and intervals from the game,
 * and decides whether to end the game or restart the level based on the number of remaining lives.
 *
 * @param {object} scene - The scene to remove tweens from.
 */
export const endLevel = (scene) => {
  removeAllTweensFromScene(scene);
  window.game.clearAllTimers();
  window.game.clearAllIntervals();
}
