import { useState } from 'react';
import styles from './game-rules.module.scss';
import Button from '../../components/button/button';

const gameElements = [
  { image: './static/assets/game-elements/cerveza.png', efecto: 'Puntos básicos.' },
  { image: './static/assets/game-elements/catavino_fino.png', efecto: '5 Puntos.' },
  { image: './static/assets/game-elements/cubata.png', efecto: '20 puntos.' },
  { image: './static/assets/game-elements/muerte.png', efecto: 'Pierdes vida / juego.' },
  { image: './static/assets/game-elements/police.png', efecto: '-5 segundos y -50 puntos.' },
  { image: './static/assets/game-elements/queso.png', efecto: '2 segundos.' },
  { image: './static/assets/game-elements/chicharron.png', efecto: '5 segundos.' },
  { image: './static/assets/game-elements/gift.png', efecto: 'Premio.' },
]
export default function GameRules(props) {
  const [pageActive, setPageActive] = useState(0);

  const { onConfirm } = props;

  return (
    <>
      {pageActive === 0 && (
        <div>
          <p>El objetivo es conseguir la máxima puntuación posible para estar en lo más alto de la clasificación. Cuánto más lejos llegues más premios y mejores tendrás!</p>
          <p>Tienes 3 vidas en cada juego.</p>
          <p>Cada nivel tienes 30 segundos para conseguir la puntuación.</p>
          <p>A continuación se muestra la tabla de objetos y sus efectos. </p>
          <Button onClick={() => setPageActive(1)}>Entendido</Button>
        </div>
      )}
      {pageActive === 1 && (
        <>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Objeto</th>
                <th>Effecto</th>
              </tr>
            </thead>
            <tbody>
              {gameElements.map((element, index) => {
                return (
                  <tr key={`element-rule-${index}`}>
                    <td><img className={styles.image} src={element.image} alt="element" /></td>
                    <td>{element.efecto}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <Button style={{ marginTop: '12px' }} onClick={onConfirm}>Entendido</Button>
        </>
      )}
    </>
  )
}
