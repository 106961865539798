import Button from "../../components/button/button";
import StatusLoading from "../../components/status-loading/status-loading";
import styles from './award-exchange.module.scss';
import React, { useState } from 'react';

export default function AwardExchange(props) {
  const { awardInfo } = props;
  const [showStatus, setShowStatus] = useState(false);

  const handleExchangeAward = () => {
    props.handleExchangeAward();
    setShowStatus(true);
  }

  return (
    <div className={styles.container}>
      <div>
        <h3 className={styles.title}>{awardInfo.award.name}</h3>
        <p className={styles['due-date']}>Caducidad: {new Date(awardInfo.due_date).toLocaleDateString()}</p>
        {props.status === 'error' && <p className={styles.error}>Error canjeando este código.</p>}
      </div>
      <img className={styles.image} src={awardInfo.award.image} alt="award info" />
      {!awardInfo.exchanged && <Button onClick={handleExchangeAward}>Canjear</Button>}
      {showStatus && <div className={styles['loading-status']}><StatusLoading status={props.status} /></div>}
      {awardInfo.exchanged && !showStatus && <p className={styles.error}>Código ya canjeado</p>}
    </div>
  )
}
