const GameButton = (x, y, label, scene, callback) => {
  const button = scene.add.text(x, y, label, { fontFamily: 'ka1', color: '#494232', fontSize: '24px' })
    .setOrigin(0.5)
    .setPadding(20)
    .setStyle({ backgroundColor: '#FFF7E8' })
    .setInteractive({ useHandCursor: true })
    .on('pointerdown', () => callback(button));

  return button;
}

export default GameButton;
