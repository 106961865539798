import React from 'react';
import styles from './layout.module.scss';
import { useLocation } from 'react-router-dom';


export default function Layout(props) {

  const location = useLocation();

  return (
    <React.Fragment>
      <div className={styles.background}></div>
      <div className={styles.container}>
        {location.pathname !== '/' && <span onClick={() => window.history.back()} className={styles.back}> Atrás </span>}
        {props.title && <h1 className={styles.title}>{props.title}</h1>}
        {props.children}
      </div>
    </React.Fragment>
  )
}
