import Button from '../../components/button/button';
import Input from '../../components/input/input';
import { useState } from 'react';
import styles from './login.module.scss';

const LoginForm = (props) => {
  const [loginValues, setLoginValues] = useState({
    username: '',
    password: '',
  });

  const handleChangeLoginValues = (e) => {
    setLoginValues({
      ...loginValues,
      [e.target.name]: e.target.value,
    });
  }

  return (
    <form onSubmit={(e) => props.handleSubmitLogin(e, loginValues)} className={styles.form}>
      <div className={styles.inputs}>
        <Input label="username" name="username" value={loginValues.username} onChange={handleChangeLoginValues} type="text" />
        <Input label="password" name="password" value={loginValues.password} onChange={handleChangeLoginValues} type="password" />
      </div>
      <Button type="submit">Login</Button>
    </form>
  )
};

export default LoginForm;
