import Phaser from 'phaser';

// Utils and helpers
import GameButton from "../utils/GameButton.js";
import { constants } from '../utils/constants.js';

class GameOverScene extends Phaser.Scene {
  constructor() {
    super("game-over-scene");
  }

  create() {
    // Add game over text
    this.gameOverText = this.add.text(this.cameras.main.centerX, this.cameras.main.centerY + 80, 'GAME OVER', {
      fontFamily: 'ka1',
      fontSize: '24px',
      color: constants.colors.status.error,
      fontStyle: 'normal'
    }).setOrigin(0.5);

    // Wait to show next game button
    setTimeout(() => {
      // Show restart game button.
      GameButton(this.cameras.main.centerX, this.cameras.main.centerY + 150, 'VOLVER A JUGAR', this, (el) => {
        // Restart values
        window.game.restartValues();

        // Send event to remove ranking table.
        window.game.events.emit('restart-game');

        // Destroy own elements.
        this.gameOverText.destroy();
        el.destroy();

        // Load game scene
        window.game.changeScene('game-scene');
      });
    }, 3000);

  }

}

export default GameOverScene;
