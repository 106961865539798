import style from './button.module.scss';

/**
 * Renders a button component with the provided onClick event handler and children.
 *
 * @param {Object} props - The props object containing the onClick event handler and children. Also can be any option for HTMLButtonElement
 * @param {Function} props.onClick - The event handler for the button's click event.
 * @param {ReactNode} props.children - The child elements to be rendered inside the button.
 * @return {JSX.Element} The rendered button component.
 */
const Button = (props) => {
  const {onClick, children} = props;

  return (
    <button {...props} className={style.button} onClick={onClick}>
      {children}
    </button>
  )
}

export default Button;
