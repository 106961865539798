export default function CookiesPolicy() {
  return (
    <div style={{ fontSize: '.75em' }}>

      <p>Fecha de última actualización: 6 de Septiembre de 2023</p>

      <h2>¿Qué son las cookies?</h2>
      <p>Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo cuando visitas un sitio web. Estos archivos contienen información que se utiliza para identificar tu dispositivo y recordar tus preferencias y actividades en el sitio.</p>

      <h2>¿Cómo utilizamos las cookies?</h2>
      <p>En La esquinita, utilizamos cookies únicamente con el propósito de mantener a los usuarios logueados en nuestra plataforma. Estas cookies son esenciales para el funcionamiento del sitio web y no recopilan información personal ni se utilizan para ningún otro propósito.</p>

      <h2>¿Cómo puedes administrar las cookies?</h2>
      <p>Puedes administrar las cookies en tu navegador. La mayoría de los navegadores te permiten bloquear o eliminar cookies de acuerdo con tus preferencias. Ten en cuenta que si desactivas las cookies esenciales, es posible que no puedas acceder a ciertas funciones de La esquinita y que tu experiencia en el sitio se vea afectada.</p>

      <h2>¿Qué cookies utilizamos?</h2>
      <p>Utilizamos las siguientes cookies:</p>
      <ul>
        <li>session-game: Esta cookie se utiliza para mantener a los usuarios logueados en La esquinita.</li>
      </ul>

      <h2>¿Dónde obtener más información?</h2>
      <p>Si deseas obtener más información sobre el uso de cookies enLa esquinita o tienes preguntas sobre nuestra política de cookies, no dudes en contactarnos a través de <a href="mailto:laesquinitadealgarve@gmail.com">laesquinitadealgarve@gmail.com</a>.</p>

      <p>Al utilizar La esquinita, aceptas el uso de cookies de acuerdo con esta política. Si no estás de acuerdo con el uso de cookies, te recomendamos que ajustes la configuración de cookies en tu navegador o que dejes de utilizar nuestro sitio web.</p>
    </div>
  )
}
