import styles from './modal.module.scss';

export default function Modal(props) {
  const handleCloseModal = () => {
    props.onClose?.();
  }

  return (
    <div className={styles.container}>
      <div className={styles.background} onClick={handleCloseModal}></div>
      <div className={styles.modal}>
        <h2 className={styles.title}>{props.title}</h2>
        <div className={styles.body}>
          {props.children}
        </div>
      </div>
    </div>
  )
}
