// Libraries
import Phaser from 'phaser';
import EventEmitter from 'eventemitter3';

// Utils and constants
import GameScene from '../phaser/GameScene.js';
import LoadScene from '../phaser/LoadScene.js';
import GameOverScene from '../phaser/GameOverScene.js';
import elements from '../utils/elements.js';
import BlurPostFX from '../phaser/pipelines/BlurPostFX.js';
import { getPlayerAvailableAwards, updatePlayerAwards, updatePlayerScore } from '../../../../services/player/player.services.js';
import { getAwardsByCategory } from '../../../../services/award/award.services.js';
import { initializeLevel } from '../utils/game.js';

// Initial values fro the game.
const initialFrequency = 1800; // ms between each object.
const initialSpeed = 3800; // ms from y=0 to y=window.innerHeight
const levelTime = 30000; // ms for each level

const initialValues = {
  score: 0,
  strikes: 0,
  level: 1,
  levelSpeed: initialSpeed,
  levelFrequency: initialFrequency,
  timeLeft: levelTime,
  levelScore: 45, // 45 is the initial score for the first level.
  lifes: 3, // We start with 3 lifes.
  possibleAwards: [], // Initially we start without possible awards.
}


/**
 * Class for all the game logic info, and connection to the database.
 * There are like a set of values and functions that can be done in the game but have no relation with scene or game itself, its just where we are storing values.
 */
class Game {
  constructor() {
    this.player = localStorage.getItem('player');
    this.values = { ...initialValues }; // Initial values
    // TODO: Review this of points values
    this.pointsValues = {
      1: { color: '#494232', size: '12px', text: `+1` },
      3: { color: '#494232', size: '12px', text: `+3` },
      8: { color: '#E48D73', size: '14px', text: `+8` },
      10: { color: '#50A5DA', size: '16px', text: `+10` },
      12: { color: '#EDB5A4', size: '16px', text: `+12` },
      20: { color: '#50A5DA', size: '16px', text: `+20` },
    };
    // Strikes: 2, 5, 10, 25
    this.keyStrikes = {
      2: { points: 3 },
      5: { points: 8 },
      10: { points: 12 },
      25: { points: 20 },
    };
    this.gameElements = elements;
    this.timers = {
      endGame: null,
      generators: [],
    };
    this.intervals = {
      gameTimer: null
    };

    this.config = {
      type: Phaser.WEBGL,
      width: window.innerWidth,
      height: window.innerHeight,
      physics: {
        default: 'arcade',
        arcade: {
          gravity: {
            y: 0
          }
        }
      },
      scene: [
        LoadScene,
        GameScene,
        GameOverScene
      ],
      backgroundColor: '#FBE5BD',
      canvas: document.getElementById('game-container'),
      dom: {
        createContainer: true
      },
      pipeline: { BlurPostFX },
    }

    this.paused = false;
    this.finished = false;
    this.muted = false;
    this.isNight = false;
    this.isChangingLevel = false;

    this.levelConfig = {
      // level time on ms.
      time: levelTime,
      /**
       * Calculates the level frequency based on the previous frequency.
       *
       * @returns {number} The level's frequency with a max value of 300ms.
       */
      frequency: () => {
        const _nextSupposedFrequency = this.values.levelFrequency - 200;
        return Math.max(300, _nextSupposedFrequency);
      },
      /**
       * Calculates the level's score based on the current level frequency.
       *
       * @returns {number} The level's score, rounded down to the nearest integer.
       */
      score: () => {
        const levelFrequencyInSeconds = this.values.levelFrequency / 1000;
        const amountOfIncrement = this.values.level > 3 ? (this.values.level / 2) : 1.5;
        const score = (this.levelConfig.time / 1000) / levelFrequencyInSeconds * amountOfIncrement;
        return Math.floor(score);
      },
      /**
       * Calculates the speed for the next level of the game.
       *
       * @returns {number} The speed for the next level, which is the maximum of 2500
       *                   and the current level speed minus 200.
       */
      speed: () => {
        const nextLevelSpeed = this.values.levelSpeed - 200;
        return Math.max(2500, nextLevelSpeed);
      },
    }

    // Events
    this.events = new EventEmitter();

    // window.addEventListener('resize', () => {
    //   window.game.game.scale.resize(window.screen.availWidth, window.screen.availHeight);
    // });
  }

  initGame() {
    this.game = new Phaser.Game(this.config);
    window.game = this;
  }

  start(scene) {
    this.isNight = false;
    this.finished = false;
    this.values = { ...initialValues };
    this.started = true;

    // Listen to game events. Communication between React - Game
    this.events.addListener('resume-game', () => {
      this.resumeGame(scene);
    });

    this.events.addListener('level-up', () => {
      initializeLevel(scene);
    });

    this.events.addListener('restart-level', () => {
      this.restartLevel(); // Restart the same level
      initializeLevel(scene, false); // Don't load again awards
    });
  }

  addPoints(points) {
    this.addStrike();
    let _pointsToAdd = points;
    const _pointsByStrike = this.keyStrikes[this.values.strikes]?.points;
    if (_pointsByStrike) {
      _pointsToAdd = _pointsByStrike < points ? points : _pointsByStrike; // If we are getting more points by the object, get the object.
      this.increaseFrequencyGameBySkill();

      // Restart strikes on 25. This means that maximum of strikes are 25.
      if (this.values.strikes === 25) {
        this.restartStrikes();
      }
    }
    this.values.score += _pointsToAdd;
    return _pointsToAdd;
  }

  increaseFrequencyGameBySkill() {
    this.values.levelFrequency = this.levelConfig.frequency();
  }

  restartLevelFrequency() {
    this.values.levelFrequency = Math.max(300, initialFrequency - (this.values.level * 200));
  }

  addStrike() {
    this.values.strikes++;
  }

  restartStrikes() {
    this.values.strikes = 0;
  }

  addBeer(beer) {
    this.beersActives.push(beer);
  }

  lossLife() {
    this.values.lifes--;
  }

  async endGame() {
    await updatePlayerScore(this.player, this.values.score);

    // Emit event to show ranking table
    this.events.emit('game-over');

    // Set as finished
    this.finished = true;
    this.started = false;

    // Remove listeners
    this.events.removeAllListeners('resume-game');
    this.events.removeAllListeners('level-up');
    this.events.removeAllListeners('restart-level');
  }

  restartValues() {
    this.values = { ...initialValues };
  }


  changeScene(scene) {
    this.game.scene.start(scene);
  }

  destroyGame() {
    this.game.destroy();
  }

  levelUp() {
    this.values.level++; // Update level
    this.restartValuesForNewLevel(); // Restart frequency, speed, time, strikes.
    this.values.levelScore += this.levelConfig.score(); // Update score
    this.values.lastLevelScore = this.values.score; // Storing last score for restarting level.
  }

  restartLevel() {
    // Restoring values as initial
    this.restartValuesForNewLevel();
    this.values.score = this.values.lastLevelScore ?? 0; // Restart score
  }

  restartValuesForNewLevel() {
    this.restartLevelFrequency(); //
    this.values.levelSpeed = this.levelConfig.speed();
    this.values.timeLeft = this.levelConfig.time;
    this.values.strikes = 0;
  }

  pauseGame(scene) {
    this.paused = true;
    try {
      scene.scene.pause(); // pause game
      scene.gameMusic.pause(); // stop music
    } catch (e) {
      console.error(e);
    }
  }

  resumeGame(scene) {
    this.paused = false;
    try {
      scene.scene.resume(); // resume game
      if (!this.muted) {
        scene.gameMusic.resume(); // resume music
      }
    } catch (e) {
      console.error(e);
    }
  }

  muteGame() {
    this.muted = true;
  }

  unmuteGame() {
    this.muted = false;
  }

  updateTime(newTimeLeft) {
    this.values = { ...this.values, timeLeft: newTimeLeft };
  }

  clearAllTimers = () => {
    this.timers.generators.forEach((timer) => {
      clearTimeout(timer);
    });

    this.timers = {
      generators: [],
    }
  }

  clearAllIntervals = () => {
    clearInterval(this.intervals.gameTimer);
    this.intervals.gameTimer = null;
  }

  /**
   * Awards
   */

  async winAward(award) {
    this.values.possibleAwards = this.values.possibleAwards.filter((a) => award._id !== a._id); // Remove from possible awards.

    this.events.emit('show-award-popup', award); // Emit event to show popup.

    // Update player awards list.
    await updatePlayerAwards(award._id);
  }

  async loadPossibleAwards() {
    // Check player info before trying to loading awards to avoid having more than 2 awards.
    const { awards } = await getPlayerAvailableAwards();
    if (awards.length < 5) {
      const category = this.getCategoryFromLevel(this.values.level);
      const _awards = await getAwardsByCategory(category);
      const _awardsToLoad = _awards.awards.filter(obj => !awards.some(({ award }) => obj._id === award._id));
      console.log(_awardsToLoad);
      this.values.possibleAwards = _awardsToLoad;
    } else {
      this.values.possibleAwards = [];
    }
  }

  /**
  * Returns the category based on the given level.
  *  Beyond level 8 -> bronze
  *  Between level 8 and 18 -> silver
  *  Above level 20 -> gold
  *
  * @param {number} level - The level to determine the category for.
  * @return {string} The category ('gold', 'silver', or 'bronze') based on the level.
  */
  getCategoryFromLevel(level) {
    switch (level) {
      case 22:
        return 'gold';
      case 10:
        return 'silver';
      default:
        return 'bronze';
    }
  }

}

export default Game;
