import { getEntity, postEntity, putEntity } from "../common/api"

const _playerEntity = 'user';

/**
 * Creates a player with the given username and password.
 *
 * @param {string} username - The username of the player.
 * @param {string} password - The password of the player.
 * @return {Promise} A promise that resolves to the created player.
 */
export const createPlayer = async (username, password) => {
  return await postEntity('auth/signup', { username, password });
}

/**
 * Logs in a player with the given username and password.
 * Will try to login with credentials, and if doesn't exists will create a new player.
 *
 * @param {string} username - The username of the player.
 * @param {string} password - The password of the player.
 * @return {Promise} A promise that resolves to the response of the login API.
 */
export const loginPlayer = async (username, password) => {
  // Try to login and if not, try to register
  const playerLogged = await postEntity('auth/login', { username, password });
  let error = null;
  if (!playerLogged) {
    const newPlayer = await createPlayer(username, password);
    if (newPlayer?.user) {
      // Now login the new user
      return await loginPlayer(username, password);
    }

    error = 'Usuario ya registrado. Compruebe la contraseña.';
  }
  return { player: playerLogged, error };
}

export const updatePlayerScore = (_id, score) => {
  const _endPointById = `${_playerEntity}`;
  return putEntity(_endPointById, { score });
}

export const getPlayers = () => {
  return getEntity(_playerEntity);
}

export const getPlayerById = (_id) => {
  return getEntity(_playerEntity, { _id });
}

export const checkLoggedPlayer = () => {
  return getEntity('auth');
}

export const getPlayersRanking = (limit) => {
  return getEntity(`${_playerEntity}/all`, { sort: '-score', limit });
}

export const getPlayerInfo = () => {
  return getEntity(`${_playerEntity}`);
}

/**
 * Awards
*/

export const updatePlayerAwards = (award) => {
  return putEntity(`${_playerEntity}/award`, { award });
}

export const getAwardInfo = (userId, awardId) => {
  return getEntity(`${_playerEntity}/award`, { userId, awardId });
}

export const exchangeAward = (userId, awardId) => {
  return putEntity(`${_playerEntity}/exchange`, { userId, awardId })
}

export const getPlayerAvailableAwards = () => {
  return getEntity(`${_playerEntity}/awards`)
}
