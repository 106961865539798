import React from 'react';
import './input.scss';

/**
 * Renders an input component with a label and value.
 *
 * @param {Object} props - The properties of the input component.
 * @param {string} props.label - The label text for the input.
 * @param {string} props.name - The name text for the input.
 * @param {string} props.value - The value of the input.
 * @param {function} props.onChange - The callback function to be called when the input value changes.
 * @return {JSX.Element} - The rendered input component.
 */
const Input = (props) => (
  <label className={'input-container'}>
    <span className={'input-label'}>{props.label}</span>
    <input onChange={props.onChange} value={props.value} name={props.name} {...props} />
  </label>
)

export default Input;
