import { useMemo } from 'react';
import styles from './awards-list.module.scss';

export default function AwardsList(props) {
  const { awards } = props;

  const sortedAwards = useMemo(() => {
    return awards.sort((a, b) => {
      if (a.exchanged < b.exchanged) {
        return -1; // If a is exchanged and b no. b will be first.
      } else {
        return a.due_date - b.due_date;
      }
    })
  }, [awards]);

  return (
    <ul className={styles.list}>
      {sortedAwards.map((_award) => {
        const { award } = _award;
        return (
          <li key={_award._id} onClick={() => props.onClick(_award)} className={`${styles.award} ${_award.exchanged ? styles.exchanged : ''}`}>
            <span className={styles.name}>{award.name}</span>
            <img className={styles['award-image']} src={award.image} alt="award info" />
            {!_award.exchanged && <span className={styles['due-date']}>Válido hasta: {new Date(_award.due_date).toLocaleDateString()}</span>}
            {_award.exchanged && <span className={styles['due-date']}>Canjeado</span>}
          </li>
        )
      })}
    </ul>
  )
}
