import { constants } from "./constants";
import { randomIntFromInterval } from "./math";


/***********************************
 ******** HELPERS FUNCTIONS ********
 ***********************************/

/**
 * Creates a rectangle sprite with an interactive hit area that is larger than
 * the given sprite, based on its dimensions and scaling factors.
 *
 * @param {Phaser.Scene} scene - The Phaser scene to add the sprite to.
 * @param {Phaser.GameObjects.Sprite} sprite - The sprite to create a hit area for.
 * @returns {Phaser.GameObjects.Rectangle} The hit area rectangle sprite.
 */
function getHittingSpriteArea(scene, sprite) {
  const _padding = 30;

  let hittingArea = scene.add.rectangle(
    sprite.x,
    sprite.y,
    sprite.width * sprite._scaleX + _padding,
    sprite.height * sprite._scaleY + _padding,
  )
    .setOrigin(sprite.originX, sprite.originY)
    .setDepth(-1)
    .setInteractive();

  return hittingArea;
}

/**
 * Destroys the targets of the given tween and removes the tween itself.
 *
 * @param {Phaser.Tweens.Tween} tween - The tween to destroy.
 * @returns {void}
 */
function destroyTween(tween) {
  // Destroy targets (sprites)
  tween.targets.forEach((el) => {
    el.destroy();
  });

  // Remove tween
  tween.remove();
}

/************************************
 ******** UTILS FOR THE GAME ********
 ************************************/

/**
 * Load a sprite in a random position and move it through the screen with a specific speed.
 * @param {Phaser.Scene} scene - The Phaser scene where the sprite will be loaded.
 * @param {object} spriteConfig - An object containing the sprite, height, onHit and onLost properties.
 * @param {string} spriteConfig.sprite - The sprite image key.
 * @param {number} spriteConfig.height - The sprite display height.
 * @param {function} spriteConfig.onHit - A callback function to be called when the sprite is clicked or touched.
 * @param {function} spriteConfig.onLost - A callback function to be called when the sprite reaches the top of the screen.
 */
export function loadSpriteInARandomPositionAndMoveIt(scene, spriteConfig) {
  const { sprite, height, onHit, onLost } = spriteConfig;
  // Put some random pon y coordinate for time between objects.
  const _initiaYPosition = window.innerHeight + randomIntFromInterval(10, 50);
  const _initialRandomXPosition = randomIntFromInterval(50, window.innerWidth - 50);

  // Add sprite to the game in this random position with a specif height
  const _spr = scene.add.image(_initialRandomXPosition, _initiaYPosition, sprite);
  _spr.displayHeight = height;
  _spr.scaleX = _spr.scaleY;

  // Add debug rectangle zone for hitting.
  const _debugRectangle = getHittingSpriteArea(scene, _spr);

  // Move sprites through screen with the speed setted on [game.levelSpeed]
  const _randomEaseIndex = randomIntFromInterval(0, constants.eases.length - 1);
  const _tween = scene.tweens.add({
    targets: [_spr, _debugRectangle],
    y: -10,
    ease: constants.eases[_randomEaseIndex],
    duration: window.game.values.levelSpeed,
    onComplete: (el) => {
      // Destroy this tween
      destroyTween(_tween)
      // Callback onLost item
      onLost?.();
    },
  });

  // Intercept click element.
  const events = ['pointerover'];
  events.forEach((event) => {
    _debugRectangle.on(event, (pointer) => {
      destroyTween(_tween);
      onHit(pointer);
    })
  });
}

/**
 * Removes all the active tweens associated with the given scene.
 * @param {Phaser.Scene} scene - The scene to remove the tweens from.
 */
export function removeAllTweensFromScene(scene) {
  scene.tweens.getAllTweens().forEach(tween => destroyTween(tween));
}


/**
 * Shows points text on the provided scene by the given coordinates and points.
 * @param {Phaser.Scene} scene - The Phaser scene to show the points text on.
 * @param {Object} coordinates - The coordinates where the points text should be shown.
 * @param {number} coordinates.x - The x coordinate of the points text.
 * @param {number} coordinates.y - The y coordinate of the points text.
 * @param {number} points - The number of points to be shown in the points text.
 * @returns {void}
 */
export function showTextOnSceneByCoordinates(scene, coordinates, textToShow, size = '14px', color = '#E48D73', duration = 300) {
  const { x, y } = coordinates;
  const textObject = scene.add.text(x, y - 10, `${textToShow}`, { fontFamily: 'ka1', fontSize: size, color: color }).setOrigin(0.5).setScale(0.5);

  // Animate text.
  const textGrow = 1.5;
  scene.tweens.add({
    targets: textObject,
    scaleY: textGrow,
    scaleX: textGrow,
    yoyo: true,
    ease: 'Sine',
    duration: duration ?? 300,
    onComplete: (el) => {
      el.remove();
      textObject.destroy();
    },
  });
}

/**
 * Sets the text of a Phaser 3 game object to a new value.
 *
 * @param {Phaser.Scene} scene - The scene that contains the game object.
 * @param {string} textName - The name of the game object's text property.
 * @param {string} newValue - The new text value to set.
 */
export function setGameText(scene, textName, newValue) {
  return scene[textName].setText(newValue);
}

/**
 * Makes the specified game object visible and moves the sprite if a pointer
 * is provided. Calls the specified callback function if it exists.
 * @param {Phaser.Scene} scene - The Phaser scene object.
 * @param {string} objectName - The name of the game object to show.
 * @param {Phaser.Input.Pointer} pointer - The pointer object, if any.
 * @param {Function} callback - The function to call after showing the game object.
 */
export function showGameObject(scene, objectName, pointer, callback) {
  const gameObject = scene[objectName];
  gameObject?.setVisible(true);
  if (pointer) {
    const { x, y } = pointer;
    gameObject?.setPosition(x, y);
  }
  callback?.();
}

/**
 * Hides a game object in the given scene by setting its visibility to false.
 *
 * @param {Phaser.Scene} scene - The scene that contains the game object.
 * @param {string} objectName - The name of the game object to hide.
 */
export function hideGameObject(scene, objectName) {
  scene[objectName].setVisible(false);
}

export function animateText(scene, text, type) {
  // Animate and change color of scoreText.
  const oldColor = text.style.color;
  const textGrow = 1.5;
  const t = scene.tweens.create({
    targets: text,
    scaleY: textGrow,
    scaleX: textGrow,
    yoyo: true,
    ease: 'Sine',
    duration: 200,
    onStart: () => {
      try {
        text.setColor(type === 'add' ? '#E48D73' : '#c42c2c');
      } catch (err) {
        console.error(err);
      }
    },
    onComplete: (el) => {
      el.remove();
      text.setColor(oldColor);
      text.setScale(1);
    },
  });

  t.play();
}

/**
 * Flashes the camera with different colors in a sequence.
 *
 * @param {object} scene - The scene object.
 * @param {array} colors - An array of objects representing RGB colors.
 * @return {Promise} A promise that resolves when the camera flashing is complete.
 */
export async function flashCamera(scene, colors) {
  scene.cameras.main.flash(150, colors[0].r, colors[0].g, colors[0].b);
  await new Promise(resolve => setTimeout(resolve, 150));
  scene.cameras.main.flash(150, colors[1].r, colors[1].g, colors[1].b);
  await new Promise(resolve => setTimeout(resolve, 150));
  scene.cameras.main.flash(150, colors[2].r, colors[2].g, colors[2].b);
  await new Promise(resolve => setTimeout(resolve, 150));
  scene.cameras.main.flashEffect.reset()
}
