import Modal from "../../components/modal/modal";
import QRCode from "react-qr-code";

export default function QrModal(props) {
  const { QRValues } = props;
  return (
    <Modal onClose={props.onClose}>
      <QRCode value={`${window.location.origin}/qr-exchange?id=${QRValues._id}&user=${QRValues.user}`} />
      <p>Muestra este código en la barra y disfruta de tu premio.</p>
    </Modal>
  )
}
