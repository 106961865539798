import { useState, useEffect, useCallback } from "react";
import useQuery from "../hooks/useQuery";
import { getAwardInfo, exchangeAward } from "../services/player/player.services";
import AwardExchange from "../ui/modules/award-exchange/award-exchange";
import Loading from "../ui/components/loading/loading";
import Layout from '../ui/modules/layout/layout';

export default function QRExchange() {
  const query = useQuery();
  const awardId = query.get('id');
  const userId = query.get('user');
  const [awardInfo, setAwardInfo] = useState(null);
  const [exchangeStatus, setExchangeStatus] = useState(null);

  const handleGetAwardInfo = useCallback(async () => {
    const { award, user } = await getAwardInfo(userId, awardId);
    if (award && user) {
      setAwardInfo({ ...award, user });
    }
  }, [awardId, userId]);

  useEffect(() => {
    if (awardId && userId) {
      handleGetAwardInfo();
    }
  }, [awardId, userId, handleGetAwardInfo]);

  const handleExchangeAward = async () => {
    const { error } = await exchangeAward(userId, awardId);
    if (error) {
      console.error(error);
      setExchangeStatus('error');
    } else {
      setExchangeStatus('success');
      setAwardInfo((ai) => { return { ...ai, exchanged: true } });
    }

  }

  return (
    <Layout title={'Canjear código'}>
      {awardInfo ?
        <AwardExchange awardInfo={awardInfo} handleExchangeAward={handleExchangeAward} status={exchangeStatus} />
        :
        <Loading text="Leyendo QR..." />
      }
    </Layout>
  )
}
