import { Link } from "react-router-dom";

import Button from "../../ui/components/button/button";
import Layout from "../../ui/modules/layout/layout";

import styles from './home.module.scss';

export default function Home() {

  return (
    <Layout>
      <div className={styles.content}>
        <img className={styles.logo} src="../../../static/assets/logos/logo.png" alt="Logo del juego" />
        <div className={styles.links}>
          <Link to="/game">
            <Button>Jugar</Button>
          </Link>
          <Link to="/awards">
            <Button>Mis premios</Button>
          </Link>
          <Link to="/clasificacion">
            <Button>Clasificacion</Button>
          </Link>
          <Link to="/terms">
            <Button>Terminos y Condiciones</Button>
          </Link>
          <Link to="/privacy">
            <Button>Politica de privacidad</Button>
          </Link>
          <Link to="/cookies">
            <Button>Cookies</Button>
          </Link>
          <a href="mailto:laesquinitadealgarve@gmail.com">
            <Button>Contacta</Button>
          </a>
        </div>
      </div>
    </Layout>
  )
}
