import Layout from "../ui/modules/layout/layout";
import PrivacyPolicy from "../ui/modules/legal/privacy-policy";

export default function Privacy() {
  return (
    <Layout title={"Política de Privacidad"}>
      <PrivacyPolicy />
    </Layout>
  )
}
