import styles from './login.module.scss';
import LoginForm from '../../ui/modules/login-form/login-form';
import { loginPlayer } from '../../services/player/player.services';
import { Navigate, useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import Loading from '../../ui/components/loading/loading';

export default function Login() {
  const navigate = useNavigate();
  const [player] = useState(localStorage.getItem('player'));
  const [loading, setLoading] = useState(false);
  // const [showInfoModal, setShowInfoModal] = useState(true);

  const [error, setError] = useState(null);

  const storeUserAndNavigate = useCallback((player) => {
    localStorage.setItem('player', player._id);
    navigate('/');
  }, [navigate]);

  const handleSubmitLogin = async (e, loginValues) => {
    e.preventDefault();
    setLoading(true);
    const { player, error } = await loginPlayer(loginValues.username, loginValues.password);
    if (error) {
      console.error(error);
      setError(error);
    }
    if (player) {
      storeUserAndNavigate(player);
    }
    setLoading(false);
  }

  if (player) return <Navigate to="/" />

  return (
    <main className={styles.container}>
      <div className={styles['main-logo']}>
        <img src="../static/assets/logos/logo.png" alt="logo" />
      </div>
      <div className={styles['login-form']}>
        {!loading && <LoginForm handleSubmitLogin={handleSubmitLogin} />}
        {loading && <Loading />}
        {error && <p className={styles.error}>{error}</p>}
      </div>
    </main>
  );
}
