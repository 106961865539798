import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { checkLoggedPlayer } from "./services/player/player.services";
import Loading from "./ui/components/loading/loading";

const _version = '1.0';

export default function App({ children }) {
  const [playerLoaded, setPlayerLoaded] = useState(false);

  // First of all will set localStorage value for player.
  const checkUser = useCallback(async () => {
    const player = await checkLoggedPlayer();
    if (player) {
      localStorage.setItem('player', player._id);
    } else {
      localStorage.removeItem('player');
    }
    setPlayerLoaded(true);
  }, [setPlayerLoaded]);

  useEffect(() => {
    checkUser();
  }, [checkUser]);

  // Game version
  useLayoutEffect(() => {
    const version = localStorage.getItem('__version');

    if (version !== _version) {
      localStorage.setItem('__version', _version);
      window.location.reload();
    }

  }, []);

  return (
    <>
      {!playerLoaded && <Loading text="Conectando..." />}
      {playerLoaded && children}
    </>
  );
}
