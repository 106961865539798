export default function PrivacyPolicy() {
  return (
    <div style={{ fontSize: '.75em' }}>

      <p>Fecha de última actualización: 6 de Septiembre de 2024</p>

      <h2>Recopilación de Información</h2>
      <p>La esquinita no recopila ni utiliza información personal de los usuarios. No solicitamos ni almacenamos datos como nombres, direcciones de correo electrónico, números de teléfono u otra información personal identificable.</p>

      <h2>Uso de Google Analytics</h2>
      <p>La esquinita utiliza Google Analytics para recopilar información de uso del juego. Esta información es completamente anónima y se utiliza únicamente con fines estadísticos para mejorar la experiencia del usuario y el rendimiento del juego.</p>

      <h2>Cookies</h2>
      <p>El sitio web de La esquinita puede utilizar cookies con el único propósito de mejorar la experiencia del usuario. Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo y no contienen información personal.</p>

      <h2>Enlaces a Terceros</h2>
      <p>Este sitio web puede contener enlaces a sitios web de terceros. La esquinita no se hace responsable de las políticas de privacidad ni del contenido de estos sitios web externos. Te recomendamos revisar las políticas de privacidad de estos terceros antes de proporcionar cualquier información personal.</p>

      <h2>Seguridad</h2>
      <p>Tomamos medidas razonables para proteger la información recopilada y mantenerla segura, pero debes tener en cuenta que la transmisión de datos a través de Internet nunca es completamente segura. Por lo tanto, no podemos garantizar la seguridad absoluta de la información transmitida o almacenada en nuestro sitio web.</p>

      <h2>Menores de Edad</h2>
      <p>Este juego está destinado exclusivamente a personas mayores de 18 años. No recopilamos información de menores de edad de manera deliberada. Si creemos que hemos recopilado información de un menor por error, eliminaremos esa información de inmediato.</p>

      <h2>Contacto</h2>
      <p>Si tienes alguna pregunta o inquietud sobre nuestra política de privacidad, por favor contáctanos a través de <a href="mailto:laesquinitadealgarve@gmail.com">laesquinitadealgarve@gmail.com</a>.</p>

      <p>Al utilizar La esquinita, aceptas nuestra política de privacidad. Si no estás de acuerdo con esta política, te recomendamos que no utilices nuestro juego.</p>
    </div>
  )
}
