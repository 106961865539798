import { getPlayerInfo } from "../services/player/player.services";
import AwardsList from "../ui/modules/awards-list/awards-list";
import Layout from "../ui/modules/layout/layout";
import { useState, useEffect, useCallback } from "react";
import Loading from "../ui/components/loading/loading";
import QrModal from "../ui/modules/qr-modal/qr-modal";

export default function Awards() {
  const [awards, setAwards] = useState(null);
  const [showQR, setShowQR] = useState(false);
  const [QRValues, setQRValues] = useState({ user: '', _id: '' });

  const getAwards = useCallback(async () => {
    const { player } = await getPlayerInfo();
    setAwards(player.awards);
    setQRValues((av) => { return { ...av, user: player._id } });
  }, []);

  useEffect(() => {
    getAwards();
  }, [getAwards]);

  const handleClickAward = (playerAward) => {
    if (!playerAward.exchanged) {
      console.log(QRValues);
      setShowQR(playerAward);
      setQRValues({ ...QRValues, _id: playerAward._id });
    }
  }

  return (
    <Layout title={'premios'}>
      {awards ? <AwardsList awards={awards} onClick={handleClickAward} /> : <Loading />}
      {showQR && <QrModal onClose={() => setShowQR(false)} QRValues={QRValues} />}
    </Layout>
  )
}
