import styles from './loading.module.scss';

export default function Loading(props) {
  return (
    <div className={styles.container}>
      <svg className={styles.loader} viewBox="25 25 50 50">
        <rect x="27" y="27" width="40" height="40" className={styles['rect-1']} stroke="#E48D73" strokeWidth="2" fill="none" />
        <rect x="30" y="30" width="34" height="34" className={styles['rect-2']} stroke="#4B4591" strokeWidth="2" fill="none" />
      </svg>
      <p>{props.text || 'Cargando...'}</p>
    </div>
  );
}
