import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Game from './js/game/Game.js';
import './css/index.scss';
import Ranking from '../../ui/modules/ranking/ranking.jsx';
import AwardPopup from '../../ui/modules/award-popup/award-popup.jsx';
import Modal from '../../ui/components/modal/modal.jsx';
import Button from '../../ui/components/button/button.jsx';
import { useNavigate } from 'react-router-dom';
import GameRules from '../../ui/modules/game-rules/game-rules.jsx';
import ConfettiExplosion from 'react-confetti-explosion';
import Layout from '../../ui/modules/layout/layout.jsx';

const confettiProps = {
  force: 0.6,
  duration: 2500,
  particleCount: 100,
  width: 300,
  colors: ['#9A0023', '#FF003C', '#AF739B', '#FAC7F3', '#F7DBF4'],
};

const GamePage = () => {
  const [showRankingTable, setShowRankingTable] = useState(false); // Ranking table visible state
  const [award, setAward] = useState(null); // Award for the popup info
  const [gamePaused, setGamePaused] = useState(false); // Game is paused
  const [exitGame, setExitGame] = useState(false); // Modal exit game
  const [startLevelPopup, setStartLevelPopup] = useState({ open: false, type: null }); // Modal to start / restart next level.
  const [firstTimePlaying, setFirstTimePlaying] = useState(localStorage.getItem('first-game') !== 'false');

  const confettiContainerRef = useRef(null);
  const [showConfetti, setShowConfetti] = useState(false);

  const navigate = useNavigate();

  useLayoutEffect(() => {
    const game = new Game();
    game.initGame();

    return () => {
      game.destroyGame();
    }
  }, []);

  const handleGameOver = useCallback(() => {
    setShowRankingTable((val) => !val);
  }, []);

  const toggleAwardPopup = useCallback((_award) => {
    setAward(_award);

    if (!_award) {
      window.game.events.emit('resume-game');
    }
  }, []);

  const handlePauseGame = useCallback(() => {
    setGamePaused(true);
  }, []);

  const showExitGameModal = useCallback(() => {
    setExitGame(true);
  }, []);

  const showLevelStartPopup = useCallback((type) => {
    setStartLevelPopup({ open: true, type }); // type: 'new' | 'restart'
  }, []);

  const showGameRules = useCallback(() => {
    setFirstTimePlaying(true);
  }, []);

  const showConfettiExplosion = useCallback((position) => {
    if (confettiContainerRef.current) {
      const { x, y } = position;
      confettiContainerRef.current.style.top = y + 'px';
      confettiContainerRef.current.style.left = x + 'px';
    }
    setShowConfetti(true);

    setTimeout(() => {
      setShowConfetti(false);
    }, 2500);
  }, [confettiContainerRef]);

  useEffect(() => {
    // Listening game events for displaying ranking table.
    window.game.events.addListener('game-over', handleGameOver); // Game over
    window.game.events.addListener('restart-game', handleGameOver); // Restart game
    window.game.events.addListener('show-award-popup', toggleAwardPopup); // Show popup
    window.game.events.addListener('pause-game', handlePauseGame); // Pause game
    window.game.events.addListener('exit-game', showExitGameModal); // Exit game
    window.game.events.addListener('start-level', showLevelStartPopup); // Start / Restart level
    window.game.events.addListener('show-info', showGameRules); // Show rules
    window.game.events.addListener('show-confetti', showConfettiExplosion); // Show confetti

    return () => {
      window.game.events.removeListener('game-over', handleGameOver);
      window.game.events.removeListener('restart-game', handleGameOver);
      window.game.events.removeListener('show-award-popup', toggleAwardPopup);
      window.game.events.removeListener('pause-game', handlePauseGame);
      window.game.events.removeListener('exit-game', showExitGameModal); // Exit game
      window.game.events.removeListener('start-level', showLevelStartPopup); // Start / Restart level
      window.game.events.removeListener('show-info', showGameRules); // Show rules
      window.game.events.removeListener('show-confetti', showConfettiExplosion); // Show confetti
    }
  }, [handleGameOver, toggleAwardPopup, handlePauseGame, showExitGameModal, showLevelStartPopup, showGameRules, showConfettiExplosion]);

  const resumeGame = useCallback(() => {
    window.game.events.emit('resume-game');
    setGamePaused(false);
  }, []);

  const closeExitGameModal = () => {
    setExitGame(false);
    resumeGame();
    console.log('close modal callback');
  }

  const handleStartLevel = () => {
    const eventToEmit = startLevelPopup.type === 'new' ? 'level-up' : 'restart-level';
    window.game.events.emit(eventToEmit);
    setStartLevelPopup(false);
  }

  const handleConfirmRules = () => {
    setFirstTimePlaying(false);
    localStorage.setItem('first-game', false);
    if (window.game.paused) {
      window.game.events.emit('resume-game');
    }
  }

  return (
    <Layout>
      <main>
        {
          firstTimePlaying && (
            <Modal title="Reglas">
              <GameRules onConfirm={handleConfirmRules} />
            </Modal>
          )
        }
        <div>
          {showRankingTable && <Ranking limit={10} />}
        </div>
        <canvas id="game-container"></canvas>
        {award && <AwardPopup onClose={toggleAwardPopup} award={award} />}
        {gamePaused && (
          <Modal title={'Un momentito'}>
            <p style={{ marginBottom: '14px' }}>El juego está en pausa. Cuando estés ready dale al botón de abajo.</p>
            <Button onClick={resumeGame}>Reanudar</Button>
          </Modal>
        )}
        {
          exitGame && (
            <Modal title="Salir del juego" onClose={closeExitGameModal}>
              <p>¿Estás seguro de que quieres salir del juego?</p>
              <p>No se guardará la puntuación actual</p>
              <Button onClick={() => {
                // TODO: End game.
                window.game.clearAllTimers();
                window.game.clearAllIntervals();
                window.game.restartValues();
                navigate('/');
              }}>Salir</Button>
            </Modal>
          )
        }
        {
          startLevelPopup.open && (
            <Modal title={startLevelPopup.type === 'new' ? " Maquina!" : 'Vuelve a intentarlo'}>
              <p style={{ fontSize: '18px', textAlign: 'center' }}>
                {startLevelPopup.type === 'new' ? 'A por el siguiente nivel' : `Te quedan ${window.game.values.lifes} vidas`}
              </p>
              {/* <p style={{ fontSize: '18px', textAlign: 'center' }}>{window.game.values.levelScore} PUNTOS</p> */}
              <Button onClick={handleStartLevel}>Vamos allá</Button>
            </Modal>
          )
        }

        <div className='confetti-explosion' ref={confettiContainerRef}>
          {showConfetti && <ConfettiExplosion {...confettiProps} />}
        </div>

      </main>
    </Layout>
  )
}

export default GamePage;
