export default function TermsAndConditions() {
  return (
    <div style={{ fontSize: '.75em' }}>

      <p>Fecha de última actualización: 6 de Septiembre de 2023</p>

      <h2>1. Elegibilidad</h2>
      <ul>
        <li>Debes ser mayor de 18 años para participar en este juego, ya que promociona el consumo de alcohol.</li>
        <li>Solo los residentes legales de España pueden participar.</li>
        <li>Los empleados de [Nombre de la Empresa] y sus familiares no son elegibles para participar en la promoción.</li>
      </ul>

      <h2>2. Registro</h2>
      <p>Para participar en el juego, debes registrarte proporcionando información veraz, actual y completa, incluyendo tu nombre de usuario y contraseña.</p>

      <h2>3. Premios</h2>
      <p>Los premios incluyen ofertas en La esquinita. Sin embargo, la disponibilidad de los premios y las marcas de cerveza elegibles están sujetas a la decisión del responsable en el momento del canje.</p>
      <p>Nos reservamos el derecho de revocar cualquier premio si sospechamos que ha sido obtenido de manera fraudulenta o en violación de estos términos y condiciones.</p>

      <h2>4. Uso Responsable de Alcohol</h2>
      <p>Promovemos el consumo responsable de alcohol. Bebe con moderación y de acuerdo con las leyes y regulaciones locales.</p>
      <p>No fomentamos ni apoyamos el consumo excesivo de alcohol ni el consumo por parte de personas menores de 18 años.</p>

      <h2>5. Privacidad</h2>
      <p>Al utilizar este juego, aceptas nuestras políticas de privacidad y el uso de Google Analytics para recoger información de uso. Puedes consultar nuestra <a href="/privacy">política de privacidad</a> para obtener más detalles sobre cómo manejamos tus datos.</p>

      <h2>6. Cambios en los Términos y Condiciones</h2>
      <p>Nos reservamos el derecho de actualizar o modificar estos términos y condiciones en cualquier momento. La fecha de última actualización se indicará en la parte superior de esta página.</p>

      <h2>7. Contacto</h2>
      <p>Si tienes alguna pregunta o inquietud sobre estos términos y condiciones, por favor contáctanos a través de <a href="mailto:laesquinitadealgarve@gmail.com">laesquinitadealgarve@gmail.com</a>.</p>

      <p>Al hacer clic en "Acepto" o continuar jugando La esquinita. El juego, aceptas cumplir con estos términos y condiciones. Si no estás de acuerdo con ellos, no podrás participar en el juego.</p>

      <p>Gracias por jugar a el juego de La esquinita y disfrutar de nuestros servicios en La esquinita. ¡Buena suerte!</p>
    </div>
  )
}
