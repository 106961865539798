import GameButton from "../utils/GameButton.js";
import Phaser from 'phaser';
import ShowCenterText from '../utils/CenterText.js';
import { startGame } from "../utils/game.js";
import createGameIcon from "../utils/gameIcon.js";
import { constants } from "../utils/constants.js";

class GameScene extends Phaser.Scene {
  constructor() {
    super("game-scene");
  }

  /**
   * Create the scene once assets were loaded.
   */
  create() {
    // Add bg
    this.bg = this.add.image(0, 0, 'bg').setOrigin(0, 0);
    this.bg.setDisplaySize(this.cameras.main.width, this.cameras.main.height);
    // this.bg.scaleX = this.bg.scaleY;

    this.nightBg = this.add.image(0, 0, 'night-bg').setOrigin(0, 0);
    this.nightBg.setDisplaySize(this.cameras.main.width, this.cameras.main.height);
    // this.nightBg.scaleX = this.bg.scaleY;
    this.nightBg.setVisible(false);

    // Set booms animation
    this.anims.create({
      key: 'kaboom-boom',
      frames: this.anims.generateFrameNumbers('kaboom', {
        start: 0,
        end: 7
      }),
      repeat: 0,
      frameRate: 16,
      hideOnComplete: true,
    });
    this.anims.create({
      key: 'jagger-boom',
      frames: this.anims.generateFrameNumbers('jagger-boom', {
        start: 0,
        end: 7
      }),
      repeat: 0,
      frameRate: 16,
      duration: 1000,
      hideOnComplete: true,
    });

    // Add explosions
    this.boom = this.physics.add.sprite(100, 100, 'kaboom');
    this.boom.setScale(2);
    this.boom.setVisible(false);
    this.boom.on('animationcomplete', () => {
      this.boom.setVisible(false);
    });

    this.jaggerBoom = this.physics.add.sprite(100, 100, 'jagger-boom');
    this.jaggerBoom.setScale(5);
    this.jaggerBoom.setVisible(false);
    this.jaggerBoom.on('animationcomplete', () => {
      this.jaggerBoom.setVisible(false);
    });

    // Add sounds
    this['beer-sound'] = this.sound.add('beer-sound', { autoplay: false, volume: 1 });
    this['muerte-sound'] = this.sound.add('muerte-sound', { autoplay: false, volume: 1 });
    this['catavino-sound'] = this.sound.add('catavino-sound', { autoplay: false, volume: 1 });
    this['queso-sound'] = this.sound.add('queso-sound', { autoplay: false, volume: 1 });
    this['chicharron-sound'] = this.sound.add('chicharron-sound', { autoplay: false, volume: 1 });
    this['cubata-sound'] = this.sound.add('cubata-sound', { autoplay: false, volume: 1 });
    this['police-sound'] = this.sound.add('police-sound', { autoplay: false, volume: 1 });
    this['gift-sound'] = this.sound.add('gift-sound', { autoplay: false, volume: 1 });
    this['combo-sound'] = this.sound.add('combo-sound', { autoplay: false, volume: 1 });
    this.gameMusic = this.sound.add('game-music', { loop: true, volume: 0.5, autoplay: false });

    // Add start game button.
    this.startButton = GameButton(this.cameras.main.centerX, this.cameras.main.centerY, 'COMENZAR JUEGO', this, (el) => {
      el.setVisible(false);
      startGame(this);
      this.gameMusic.play();
    });

    // Add level texts
    this.levelText = ShowCenterText(this, `Nivel ${window.game.values.level}`, '56px');
    this.levelText.setVisible(false);
    this.nextLevelScoreText = this.add.text(this.levelText.x, this.levelText.y + 64, `${window.game.values.levelScore} PUNTOS`, { fontFamily: 'ka1', fontSize: '24px', color: constants.colors.text.secondary }).setOrigin(0.5, 0.5);
    this.nextLevelScoreText.setVisible(false);

    // Add game info.
    this.scoreText = this.add.text(this.cameras.main.width / 2, 60, `00`, { fontFamily: 'joystix', fontSize: '36px', color: constants.colors.text.primary }).setOrigin(0.5, 0);
    this.timeText = this.add.text(30, 30, `${window.game.levelConfig.time / 1000}`, { fontFamily: 'joystix', fontSize: '32px', color: constants.colors.text.secondary });
    this.scoreText.ignoreDestroy = true;
    this.timeText.ignoreDestroy = true;

    const lifeIconWidth = 30;

    for (let i = 0; i < window.game.values.lifes; i++) {
      const iconPositionX = this.cameras.main.width - 30 - (lifeIconWidth * i);
      this[`life-icon-${i}`] = this.add.image(iconPositionX, 30, 'life-icon').setOrigin(0.5, 0);
      this[`life-icon-${i}`].setDisplaySize(lifeIconWidth, lifeIconWidth);
    }

    // Add game icons
    const iconsSize = 20;
    const icons = [
      {
        icon: 'sound-on',
        callback: () => {
          if (window.game.started) {
            this.gameMusic.pause();
            this['sound-off'].setVisible(true); // Show sound off icon
            this['sound-on'].setVisible(false); // Hide sound on icon
            window.game.muteGame();
          }
        },
        position: { x: this.cameras.main.width / 2 + 60, y: 20 }
      },
      {
        icon: 'sound-off',
        callback: () => {
          if (window.game.started) {
            this.gameMusic.resume();
            this['sound-off'].setVisible(false); // Hide sound off icon
            this['sound-on'].setVisible(true); // Show sound on icon
            window.game.unmuteGame();
          }
        },
        position: { x: this.cameras.main.width / 2 + 60, y: 20 }
      },
      {
        icon: 'pause-icon',
        callback: () => {
          if (window.game.started) {
            window.game.pauseGame(this); // Pause game
            window.game.events.emit('pause-game'); // Emit event to show paused modal in frontend
          }
        },
        position: { x: this.cameras.main.width / 2 + 20, y: 20 }
      },
      {
        icon: 'home-icon',
        callback: () => {
          if (window.game.started) {
            window.game.events.emit('exit-game'); // Show popup for exit game
            window.game.pauseGame(this); // Pause game
          } else {
            window.location.href = window.location.origin; // Redirect to home
          }
        },
        position: { x: this.cameras.main.width / 2 - 20, y: 20 }
      },
      {
        icon: 'info-icon',
        callback: () => {
          window.game.events.emit('show-info'); // Show game rules.
          if (window.game.started) {
            window.game.pauseGame(this); // Pause game
          }
        },
        position: { x: this.cameras.main.width / 2 - 60, y: 20 }
      }
    ];
    for (const icon of icons) {
      this[icon.icon] = createGameIcon(this, iconsSize, icon.callback, icon.icon, icon.position);
    }

    this['sound-off'].setVisible(false);

    // TODO: Resize
    // this.scale.on('resize', this.resize, this);

    // Blur listener for pausing the game.
    window.addEventListener('blur', () => {
      if (window.game.started) {
        window.game.pauseGame(this); // Pause game
        window.game.events.emit('pause-game'); // Emit event to show paused modal in frontend
      }
    });

    // window.addEventListener('focus', () => {
    //   if (this.blured) {
    //     window.game.resumeGame(this);
    //     this.blured = false;
    //   }
    // });
  }

  // resize (gameSize, baseSize, displaySize, resolution) {
  //   var width = gameSize.width;
  //   var height = gameSize.height;

  //   this.cameras.resize(width, height);

  //   this.bg.setScale(Math.max(this.cameras.main.height / this.bg.height, this.cameras.main.width / this.bg.width));
  //   this.sky.setScale(Math.max(this.cameras.main.height / this.sky.height, this.cameras.main.width / this.sky.width));
  //   this.esq_bg.setScale(this.cameras.main.width / this.esq_bg.width);

  //   // this.logo.setPosition(width / 2, height / 2);
  // }

};

export default GameScene;
