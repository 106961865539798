import Layout from "../ui/modules/layout/layout";
import CookiesPolicy from "../ui/modules/legal/cookies";

export default function Cookies() {
  return (
    <Layout title="Politica de Cookies">
      <CookiesPolicy />
    </Layout>
  )
}
