import Layout from "../ui/modules/layout/layout";
import TermsAndConditions from "../ui/modules/legal/term-conditions";

export default function Terms() {
  return (
    <Layout title="Términos y condiciones">
      <TermsAndConditions />
    </Layout>
  )
}
