import styles from './status-loading.module.scss';

/**
 * Render a loading status component. When the status is 'success' or 'error' will show an animation for this status
 *
 * @param {object} props - The properties for the component.
 * @param {string} props.status - The status of the loading component. 'success' | 'error'
 * @return {JSX.Element} The loading status component.
 */
export default function StatusLoading(props) {
  const { status } = props;

  return (
    <div className={styles.container}>
      <svg viewBox="0 0 87 87" className={styles[status]}>
        <circle stroke="rgba(75, 69, 145, .5)" strokeWidth="4" cx="44" fill="none" cy="44" r="40" />
        <g className={styles.rotating}>
          <circle className={`${styles['loading-line']} ${styles[status]}`} fill="none" stroke="rgba(75, 69, 145)" strokeWidth="4" cx="44" cy="44" r="40" />
        </g>
        {
          status === 'error' && <g className="error-lines">
            <line x1="28" y1="28" x2="60" y2="60" stroke="#F74444" strokeWidth="3" className={styles['e-line']} />
            <line x1="60" y1="28" x2="28" y2="60" stroke="#F74444" strokeWidth="3" className={styles['e-line']} />
          </g>
        }
        {

          status === 'success' && <g className="success-lines">
            <line x1="26" y1="44" x2="36" y2="54" strokeWidth="3" stroke="#53a823" className={styles['s-line-1']} />
            <line x1="36" y1="54" x2="60" y2="28" strokeWidth="3" stroke="#53a823" className={styles['s-line-2']} />
          </g>
        }
      </svg>
    </div>
  )
}
