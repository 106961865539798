import { useCallback, useEffect, useState } from 'react';
import styles from './ranking.module.scss';
import { getPlayersRanking } from '../../../services/player/player.services';
import Loading from '../../../ui/components/loading/loading';

export default function Ranking({ limit }) {
  const [rankingData, setRankingData] = useState([]);

  const fetchPlayersData = useCallback(async () => {
    const { players } = await getPlayersRanking(limit);
    setRankingData(players);
  }, [limit]);

  useEffect(() => {
    fetchPlayersData();
  }, [fetchPlayersData]);

  return (
    rankingData.length ? (
      <table className={styles['ranking-table']}>
        <thead>
          <tr>
            <th>Pos</th>
            <th>Username</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          {rankingData.map(({ _id, username, score }, i) => {
            const _player = localStorage.getItem('player');
            return (
              <tr className={_player === _id ? styles['player-info'] : ''} key={_id} data-night={window.game?.isNight}>
                <td>{i + 1}</td>
                <td>{username}</td>
                <td>{score}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
      :
      <div className={styles.loading}>
        <Loading text="Cargando..." />
      </div>
  );
}
