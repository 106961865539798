const _prodEndpoint = 'https://api.laesquinitadealgarve.com';
const _rootEndPoint = process.env.NODE_ENV === 'production' ? _prodEndpoint : 'http://localhost:5000';

export const getEntity = async (entity, query) => {
  return _fetchToApiRest(entity, 'get', null, query);
}

export const postEntity = async (entity, data) => {
  return _fetchToApiRest(entity, 'post', data);
}

export const putEntity = async (entity, data) => {
  return _fetchToApiRest(entity, 'put', data);
}

export const deleteEntity = async (entity, data) => {
  return _fetchToApiRest(entity, 'delete', data);
}

const _fetchToApiRest = async (entity, method, data, query) => {
  const endpoint = `${_rootEndPoint}/${entity}`;
  const response = await fetch(
    endpoint + (query ? `?${new URLSearchParams(query)}` : ''),
    {
      method,
      headers: { "Content-Type": "application/json" },
      body: data ? JSON.stringify(data) : undefined,
      credentials: 'include'
    }
  );
  if (response?.ok) {
    const _data = await response.json();
    return _data;
  } else {
    console.error('Some error fetching data');
    return null;
  }
}
