import { Navigate } from 'react-router-dom';
import { useState } from 'react';

import Modal from '../ui/components/modal/modal';
import Button from '../ui/components/button/button';

export default function PrivateRoute({ children }) {
  const [player] = useState(localStorage.getItem('player'));

  // Modal for +18.
  const [showAwareModal, setShowAwareModal] = useState(localStorage.getItem('isLegal') !== 'true');
  const handleConfirmAge = () => {
    localStorage.setItem('isLegal', 'true');
    setShowAwareModal(false);
  }

  // Modal legal stuff
  const [showLegalStuffModal, setShowLegalStuffModal] = useState(localStorage.getItem('legalAccepted') !== 'true');
  const handleConfirmLegalStuff = () => {
    localStorage.setItem('legalAccepted', 'true');
    setShowLegalStuffModal(false);
  }

  return (
    <>
      {
        showAwareModal && (
          <Modal title="Aviso legal">
            <p>Este juego está dirigido a mayores de edad.</p>
            <p>Confirmo que soy mayor de edad y tengo más de 18 años o la edad mínima para beber en mi pais.</p>
            <Button style={{ margin: '12px 0' }} onClick={handleConfirmAge}>Aceptar</Button>
            <p style={{ fontSize: '10px' }}>Este mensaje no se volverá a mostrar en esta sesión.</p>
          </Modal>
        )
      }
      {
        showLegalStuffModal && !showAwareModal && (
          <Modal title="Aviso legal">
            <p>Usamos cookies para mejorar tu experiencia. A continuar estás aceptando nuestra <a style={{ color: '#4b4591' }} href="/cookies">política de cookies</a></p>
            <p>También aceptas nuestras <a style={{ color: '#4b4591' }} href="/terms">Condiciones de uso</a>,y nuestra <a style={{ color: '#4b4591' }} href="/privacy">Política de Privacidad</a></p>
            <Button style={{ margin: '12px 0' }} onClick={handleConfirmLegalStuff}>Aceptar</Button>
            <p style={{ fontSize: '10px' }}>Este mensaje no se volverá a mostrar en esta sesión.</p>
          </Modal>
        )
      }
      {!player ? <Navigate to="/login" /> : children}
    </>
  )
}
