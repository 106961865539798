
import { Routes, Route, BrowserRouter } from "react-router-dom";
import PrivateRoute from "./private-route";

// Private routes
import GamePage from "../pages/game";
import Login from "../pages/login";
import Home from "../pages/home";
import Awards from "../pages/awards";
import Clasificacion from "../pages/clasificacion";

// Exchange QR
import QRExchange from "../pages/qr-exchange";

// Legal pages
import Terms from "../pages/terms";
import Cookies from "../pages/cookies";
import Privacy from "../pages/privacy";

const AppRouter = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/game" element={<PrivateRoute><GamePage /></PrivateRoute>} />
      <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
      <Route path="/awards" element={<PrivateRoute><Awards /></PrivateRoute>} />
      <Route path="/clasificacion" element={<PrivateRoute><Clasificacion /></PrivateRoute>} />
      <Route path="/qr-exchange" element={<QRExchange />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/cookies" element={<Cookies />} />
    </Routes>
  </BrowserRouter>
)

export default AppRouter;
