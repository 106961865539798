import Phaser from 'phaser';
import loadFont from "../utils/loadFont.js";

class LoadScene extends Phaser.Scene {
  constructor() {
    super('load-scene');
  }

  /**
   * Load initial assets.
   * - Load background
   * - Load all our sprites available.
   */
  preload() {
    // Load fonts
    loadFont("ka1", "../static/assets/fonts/ka1.ttf");
    loadFont("joystix", "../static/assets/fonts/joystix.otf");

    // Load backgrounds
    this.load.image('bg', '../../static/assets/backgrounds/day_bg.png');
    this.load.image('night-bg', '../../static/assets/backgrounds/night_bg.png');

    // Load beer sprite.
    this.load.image(`beer`, '../../static/assets/game-elements/cerveza.png');

    // Load Other elements
    this.load.image(`jagger`, '../../static/assets/game-elements/muerte.png');
    this.load.image(`cubata`, '../../static/assets/game-elements/cubata.png');
    this.load.image(`catavino`, '../../static/assets/game-elements/catavino_fino.png');
    this.load.image(`queso`, '../../static/assets/game-elements/queso.png');
    this.load.image(`chicharron`, '../../static/assets/game-elements/chicharron.png');
    this.load.image('police', '../../static/assets/game-elements/police.png');
    this.load.image('gift', '../../static/assets/game-elements/gift.png');

    // Load icons
    this.load.image(`life-icon`, '../../static/assets/icons/pipo_vida.png');
    this.load.image(`pause-icon`, '../../static/assets/icons/pause.png');
    this.load.image(`sound-on`, '../../static/assets/icons/sound-on.png');
    this.load.image(`sound-off`, '../../static/assets/icons/sound-off.png');
    this.load.image(`home-icon`, '../../static/assets/icons/home.png');
    this.load.image('info-icon', '../../static/assets/icons/info.png');


    // Load explosion sprite sheet
    this.load.spritesheet('kaboom', '../../static/assets/animations/kaboom.png', {
      frameWidth: 64,
      frameHeight: 64
    });

    // load jagger explosion
    this.load.spritesheet('jagger-boom', '../../static/assets/animations/explo_jagg.png', {
      frameWidth: 64,
      frameHeight: 64
    });

    // load sounds
    this.load.audio('beer-sound', '../../static/assets/audio/botellin.wav');
    this.load.audio('catavino-sound', '../../static/assets/audio/catavino.wav');
    this.load.audio('chicharron-sound', '../../static/assets/audio/chicharron.wav');
    this.load.audio('cubata-sound', '../../static/assets/audio/cubata.wav');
    this.load.audio('muerte-sound', '../../static/assets/audio/muerte_jagger.wav');
    this.load.audio('police-sound', '../../static/assets/audio/policia.wav');
    this.load.audio('gift-sound', '../../static/assets/audio/premio.wav');
    this.load.audio('queso-sound', '../../static/assets/audio/queso.wav');
    this.load.audio('game-music', '../../static/assets/audio/musica_fondo.mp3');
    this.load.audio('combo-sound', '../../static/assets/audio/combo.mp3')

    // Loading bar
    const width = this.cameras.main.width;
    const height = this.cameras.main.height;

    const progressBar = this.add.graphics();
    const progressBox = this.add.graphics();
    progressBox.fillStyle(0x50a5da, 1);
    progressBox.fillRect(30, height / 2 - 15, width - 60, 30);
    progressBar.setDepth(1);

    const percentText = this.make.text({
      x: width / 2,
      y: height / 2,
      text: '0%',
      style: {
        font: '14px monospace',
        fill: '#FFFFFF'
      }
    });
    percentText.setOrigin(0.5, 0.5);
    percentText.setDepth(2);


    // Loading progess event.
    this.load.on('progress', function (value) {
      percentText.setText(parseInt(value * 100) + '%');

      progressBar.clear();
      progressBar.fillStyle(0x494232, 1);
      progressBar.fillRect(40, height / 2 - 10, (width - 80) * value, 20);
    });

    this.load.on('complete', () => {
      progressBar.destroy();
      progressBox.destroy();
      percentText.destroy();
      window.game.changeScene('game-scene');
    });
  }

  // create() {
  //   this.scoreText = this.add.text(30, 30, `Login`, { fontFamily: 'ka1', fontSize: '36px', color: '#50A5DA' });
  // }
}

export default LoadScene;
