import Button from '../../components/button/button';
import Modal from '../../components/modal/modal';
import styles from './award-popup.module.scss';

export default function AwardPopup(props) {
  const { award } = props;
  return (
    <Modal title="Woooho! Premio">
      <div className={styles['award-info']}>
        <h2 className={styles.title}>{award.name}</h2>
        <img className={styles['award-image']} src={award.image} alt="award info" />
        <p className={styles.description}>{award.description}</p>
      </div>
      <Button onClick={() => props.onClose()}>Aceptar</Button>
      <p className={styles.description}>Para ver tus premios y canjearlos debes ir al menú de premios**</p>
    </Modal>
  )
}
