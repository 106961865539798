import { randomIntFromInterval, pad } from '../utils/math.js';
import BlurPostFX from '../phaser/pipelines/BlurPostFX.js';
import { handleHitPointsElement, handleHitSecondsElement, lossLife } from './game.js';
import { showGameObject, setGameText, animateText, showTextOnSceneByCoordinates, flashCamera } from './scene.js';


const elements = [
  {
    probability: (level) => level > 5 ? true : randomIntFromInterval(1, 5) === 5,
    sprite: 'jagger',
    height: 70,
    effect: (scene, pointer) => {
      // Check if level is changing for avoid bugs situations.
      if (!window.game.isChangingLevel) {
        if (!window.game.muted) {
          scene.sound.play('muerte-sound', { duration: .5 });
        }

        showGameObject(scene, 'jaggerBoom', pointer, () => {
          scene.jaggerBoom.play('jagger-boom');
        });

        showTextOnSceneByCoordinates(scene, { x: scene.cameras.main.width / 2, y: scene.cameras.main.height / 2 }, 'FUCKING JAGGER', '20px', '#D35D9A', 1500);

        lossLife(scene);
      }
    },
    numOcurrencies: (level) => {
      // If level is greater than 5, set the maximum number of occurrences to the level and the minimum to 2. Otherwise, set both to 1.
      const maxOccurrences = level > 5 ? level : 1;
      const minOccurrences = level > 5 ? 3 : 1;

      // Generate a random integer between the minimum and maximum occurrences and return it.
      return randomIntFromInterval(minOccurrences, maxOccurrences);
    }
  },
  {
    probability: (level) => level > 5 ? true : randomIntFromInterval(1, 5) === 5,
    sprite: 'cubata',
    height: 45,
    effect: (scene, pointer) => {
      if (!window.game.muted) {
        scene.sound.play('cubata-sound', { duration: .5 });
      }

      const camera = scene.cameras.main;
      // Blur camera
      camera.setPostPipeline(BlurPostFX);
      // Reset postpipeline after hoe much seconds that left.
      setTimeout(() => camera.resetPostPipeline(), 5000);
      const _pointsToAdd = 20;
      handleHitPointsElement(scene, pointer, _pointsToAdd);
    },
    onLost: () => window.game.restartStrikes(),
    numOcurrencies: (level) => {
      // If level is greater than 5, set the maximum number of occurrences to 5 and the minimum to 2. Otherwise, set both to 1.
      const maxOccurrences = level > 5 ? level : 1;
      const minOccurrences = level > 5 ? 2 : 1;

      // Generate a random integer between the minimum and maximum occurrences and return it.
      return randomIntFromInterval(minOccurrences, maxOccurrences);
    }
  },
  {
    probability: () => true, // Always will be show.
    sprite: 'catavino',
    height: 40,
    effect: (scene, pointer) => {
      if (!window.game.muted) {
        scene.sound.play('catavino-sound', { duration: .5 });
      }

      const _pointsToAdd = 10;
      handleHitPointsElement(scene, pointer, _pointsToAdd);
    },
    onLost: () => window.game.restartStrikes(),
    numOcurrencies: (level) => {
      // If level is greater than 3, set the maximum number of occurrences to the level and the minimum to 2. Otherwise, set both to 1.
      const maxOccurrences = level > 3 ? level : 2;
      const minOccurrences = 2;

      // Generate a random integer between the minimum and maximum occurrences and return it.
      return randomIntFromInterval(minOccurrences, maxOccurrences);
    }
  },
  {
    probability: (level) => level < 10 ? true : randomIntFromInterval(1, 10) <= 3, // 30% of probability after level 10 always showing cheese for time.
    sprite: 'queso',
    height: 35,
    effect: (scene, pointer) => {
      if (!window.game.muted) {
        scene.sound.play('queso-sound', { duration: .5 });
      }

      const textInfo = {
        text: '+2',
      }
      const _secondToAdd = 2000;
      handleHitSecondsElement(scene, pointer, _secondToAdd, textInfo);
    },
    numOcurrencies: (level) => {
      return level > 25 ? 2 : 1;
    }
  },
  {
    probability: (level) => level > 5 ? true : randomIntFromInterval(1, 10) === 10,
    sprite: 'chicharron',
    height: 30,
    effect: (scene, pointer) => {
      if (!window.game.muted) {
        scene.sound.play('chicharron-sound', { duration: .5 });
      }

      const textInfo = {
        text: '+5',
      }
      const _secondToAdd = 5000;
      handleHitSecondsElement(scene, pointer, _secondToAdd, textInfo);
    },
    numOcurrencies: (level) => {
      return level > 25 ? 2 : 1; // limit to one chicharrones to show below level 25.
    }
  },
  {
    probability: (level) => level > 5 ? true : randomIntFromInterval(1, 3) === 3,
    sprite: 'police',
    height: 40,
    effect: (scene, pointer) => {
      if (!window.game.muted) {
        scene.sound.play('police-sound', { duration: .5 });
      }

      const textInfo = {
        text: '-5 & -50',
      }
      const _secondToAdd = -5000;
      const _pointsToAdd = -50;
      handleHitSecondsElement(scene, pointer, _secondToAdd, textInfo);


      // decrease points
      window.game.addPoints(_pointsToAdd);
      const text = setGameText(scene, 'scoreText', `${pad(window.game.values.score)}`);
      animateText(scene, text, 'subtract');


      const red = { r: 255, g: 0, b: 0 };
      const blue = { r: 0, g: 0, b: 255 };
      flashCamera(scene, [red, blue, red]);
    },
    // onLost: () => window.game.restartStrikes(),
    numOcurrencies: (level) => {
      // If level is greater than 5, set the maximum number of occurrences to the level and the minimum to 2. Otherwise, set both to 1.
      const maxOccurrences = level > 5 ? level : 1;
      const minOccurrences = level > 5 ? 5 : 1;

      // Generate a random integer between the minimum and maximum occurrences and return it.
      return randomIntFromInterval(minOccurrences, maxOccurrences);
    }
  },
  {
    probability: (level) => {
      return false;
      // if (!window.game.values.possibleAwards.length) return false; // If there is not possible awards return false.
      // const _randomNumber = randomIntFromInterval(1, 200);
      // if (level >= 22) {
      //   return _randomNumber < 100; // Gold award => 50%. as rewards to be so good.
      // }

      // if (level >= 10) {
      //   return _randomNumber < 20; // Silver award => 10%.
      // }

      // return _randomNumber < 50; // Bronze award => 25%.
    },
    sprite: 'gift',
    height: 65,
    effect: (scene, pointer) => {
      if (!window.game.muted) {
        scene.sound.play('gift-sound', { duration: .5 });
      }

      window.game.pauseGame(scene); // Pause game
      console.log(window.game.values.possibleAwards);
      // Get an award for the possibilities
      const _award = window.game.values.possibleAwards[Math.floor(Math.random() * window.game.values.possibleAwards.length)];
      window.game.winAward(_award);

      window.game.events.emit('show-confetti', pointer);
    },
    numOcurrencies: () => {
      return 0;
      // return randomIntFromInterval(0, 1); // Just 0 or 1.
    }
  },
];

export default elements;
